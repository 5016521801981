.end-workout-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
  }
  
  .summary-details {
    margin: 20px 0;
  }
  
  .actions {
    display: flex;
    gap: 10px;
  }
  
  .button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .button:hover {
    background-color: #0056b3;
  }