/* Base styles for mobile-first design */
.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  transition: opacity 0.2s;
}

.my-form {
  --_clr-primary: #666;
  --_clr-hover: #727272;
  --_clr-checked: #127acf;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.my-form > div {
  --_clr-current: var(--_clr-primary);

  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.my-form > div {
  margin-block-start: 0.5rem;
}
.my-form label {
  cursor: pointer;
  color: var(--_clr-current);
  transition: color 150ms ease-in-out;
}
/* styled radio */
.my-form input[type="radio"] {
  appearance: none;
  outline: none;
  width: 1.5rem;
  height: 1.5rem;
  aspect-ratio: 1;
  padding: 0.25rem;
  background: transparent;
  border: 1px solid var(--_clr-current);
  border-radius: 50%;
  display: grid;
  place-content: center;
  cursor: pointer;
  position: relative;
}
.my-form input[type="radio"]::after {
  content: "";
  position: absolute;
  inset: 0.25rem;
  opacity: 0;
  scale: 0;
  transition:
    opacity 150ms ease-in-out,
    scale 150ms ease-in-out;
  background-color: var(--_clr-checked);
  border-radius: inherit;
}

.my-form label:hover,
.my-form input[type="radio"]:focus-visible,
.my-form input[type="radio"]:focus-visible + label,
.my-form input[type="radio"]:hover,
.my-form input[type="radio"]:hover + label {
  --_clr-current: var(--_clr-hover);
}
.my-form input[type="radio"]:focus-visible::after,
.my-form input[type="radio"]:hover::after {
  opacity: 0.5;
  scale: 1;
  background-color: var(--_clr-hover);
}

.my-form input[type="radio"]:checked + label:not(:hover),
.my-form input[type="radio"]:checked:not(:hover) {
  --_clr-current: var(--_clr-checked);
}
.my-form input[type="radio"]:checked::after {
  opacity: 1;
  scale: 1;
}

.checkbox-wrapper .slider-switch {
  display: none;
  margin-top: 50px;
}

.goal-button-container {
  position: static;
  display: inline-block;
  width: auto;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.goal-button-container,
.slider-switch-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}


.checkbox-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -7vh;
  width: max-content;
}

.checkbox-wrapper .slider-switch + label {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #78768d;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 15px;
  /* bottom: ; */
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-wrapper .slider-switch + label::before,
  .checkbox-wrapper .slider-switch + label::after {
  content: '';
  display: block;
}

.checkbox-wrapper .slider-switch + label::before {
  background-color: #3b3b3b;
  border-radius: 500px;
  height: 45px;
  margin-right: 8px;
  -webkit-transition: background-color 0.125s ease-out;
  transition: background-color 0.125s ease-out;
  width: 225px;
}

.checkbox-wrapper .slider-switch + label::after {
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 3px 1px 0 rgba(37, 34, 71, 0.05), 0 2px 2px 0 rgba(37, 34, 71, 0.1), 0 3px 3px 0 rgba(37, 34, 71, 0.05);
  height: 43px;
  left: 1px;
  position: absolute;
  top: 1px;
  -webkit-transition: -webkit-transform 0.125s ease-out;
  transition: -webkit-transform 0.125s ease-out;
  transition: transform 0.125s ease-out;
  transition: transform 0.125s ease-out, -webkit-transform 0.125s ease-out;
  width: 43px;
}

.checkbox-wrapper .slider-switch + label .slider-switch-x-text {
  display: block;
  margin-right: .3em;
}

.checkbox-wrapper .slider-switch + label .slider-switch-x-toggletext {
  display: block;
  font-weight: bold;
  height: 15px;
  overflow: hidden;
  position: relative;
  width: 25px;
}

.checkbox-wrapper .slider-switch + label .slider-switch-x-unchecked,
  .checkbox-wrapper .slider-switch + label .slider-switch-x-checked {
  left: 0;
  position: absolute;
  bottom: -50;
  -webkit-transition: opacity 0.125s ease-out, -webkit-transform 0.125s ease-out;
  transition: opacity 0.125s ease-out, -webkit-transform 0.125s ease-out;
  transition: transform 0.125s ease-out, opacity 0.125s ease-out;
  transition: transform 0.125s ease-out, opacity 0.125s ease-out, -webkit-transform 0.125s ease-out;
}

.checkbox-wrapper .slider-switch + label .slider-switch-x-unchecked {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}

.checkbox-wrapper .slider-switch + label .slider-switch-x-checked {
  opacity: 0;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

.checkbox-wrapper .slider-switch + label .slider-switch-x-hiddenlabel {
  position: absolute;
  visibility: hidden;
}

.checkbox-wrapper .slider-switch:checked + label::before {
  background-color: #008cff;
}

.checkbox-wrapper .slider-switch:checked + label::after {
  -webkit-transform: translate3d(180px, 0, 0);
  transform: translate3d(180px, 0, 0);
}

.checkbox-wrapper .slider-switch:checked + label .slider-switch-x-unchecked {
  opacity: 0;
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}

.checkbox-wrapper .slider-switch:checked + label .slider-switch-x-checked {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}

.input_video, .output_canvas {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  border-radius: 5px;
}

.overlay {
  border-radius: 5px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #333; */
  /* color: black; */
  padding: 0px 20px;
}

.nav-logout {
  letter-spacing: 5px;
  color: black;
}


.nav-brand {
  font-size: 1.5rem;
}

.nav-items {
  display: flex;
  color: black;
  align-items: center;
  text-decoration: none;
  font-family: "Kanit", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.nav-items:hover {
  /* display: flex; */
  color: #2937f0;
  text-decoration: none;
  /* align-items: center; */
}

.nav-item {
  display: flex; /* Use flexbox */
  align-items: center; /* Align items vertically */
  /* margin-left: 10px; */
  cursor: pointer;
  padding: 15px;
  border-radius: 50rem !important;
  letter-spacing: 1px;
}

.nav-item .material-symbols-outlined {
  margin-right: 8px; /* Add margin between icon and text */
}

.nav-item:first-child {
  margin-left: 0;
}
.welcome-text {
  display: none;
}
.arm-container {
  scale: 1;
  transform: rotate(270deg);
  padding-right: 4vh;
}

.fire {
  width: 2em;
  height: 6.5em;
  background-color: black;
  border-radius: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.label {
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  margin: 0.25em 0;
  border-top: 0.2em solid black;
  cursor: pointer;
}

.input {
  display: none;
}

.label.red {
  background-color: #9e0000;
  box-shadow: 0 0 2em #990000;
}

.label.orange {
  background-color: #999900;
  box-shadow: 0 0 2em #999900;
}

.label.green {
  background-color: #009900;
  box-shadow: 0 0 2em #009900;
}

.label.red:hover {
  background-color: red;
}

.label.orange:hover {
  background-color: orange;
}

.label.green:hover {
  background-color: lime;
}

.label.red:has(input:checked) {
  background-color: red;
  box-shadow: 0 0 2em red;
}

.label.orange:has(input:checked) {
  background-color: orange;
  box-shadow: 0 0 2em orange;
}

.label.green:has(input:checked) {
  background-color: lime;
  box-shadow: 0 0 2em lime;
}

.content-wrapper1 {
  justify-content: center;
  z-index: 9998;
  align-items: center;
  text-align: center;
  position: absolute;
}

.goal-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.checkbox-parent {
  position: static; /* Reset position for desktop */
  margin-top: 1rem; /* Add spacing between video feed and checkbox-parent */
  width: 100%; /* Ensure it spans the full width */
  display: flex;
  justify-content: center; /* Center it horizontally */
  align-items: center; /* Center content vertically */
}

.checkbox-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
}

.hidden-video-text {
  color: rgb(255, 255, 255);
  background-color: #c5c5c5;
  border-radius: 5px;
  margin: auto;
  height: 100%;
  position: relative;
}

.hidden-video-text p {
  color: rgb(245, 245, 245);
}

.dropdown {
  position: relative;
  display: inline-block;
  z-index: 1000;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  color: black;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  height: max-content;
  right: 0;
  margin: 0 auto;
  border-radius: 2rem !important;
}

.dropdown-content a {
  color: black;
  padding: 12px auto;
  text-align: center;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
  border-radius: 50rem !important;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
}

.nav-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 15px;
  border-radius: 50rem !important;
  letter-spacing: 1px;
}

.content-wrapper {
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column; /* Stack elements vertically for mobile */
  align-items: center; /* Center elements horizontally */
  justify-content: center; /* Center elements vertically */
  gap: 1rem; /* Add spacing between elements */
  width: 100%; /* Full width for mobile */
}
.logout-button {
  position: absolute;
  top: 10px;
  right: 5%;
  padding: 8px 16px;
  border-radius: 20px;
  background-color: #fc4c4c;
  color: white;
  border: none;
  cursor: pointer;
  width: min-content;
}

.gradient {
  background: linear-gradient(90deg, #0066cc 0%, #c500cc 100%);
  border-radius: 50%;
  width: max-content;
}

.timer-input {
  color: rgb(0, 0, 0);
  border: 2px solid #8707ff;
  border-radius: 10px;
  padding: 10px 25px;
  background: transparent;
  width: 100%;
  box-sizing: border-box;
}

.slider {
  -webkit-appearance: none;
  width: 12vw;
  height: 15px;
  margin-top: 12vh;
  border-radius: 5px;
  background: linear-gradient(90deg, #0066CC 0%, #c500cc 100%);
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
  /* margin-bottom: 20px; Add spacing between slider and value */
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: hsl(210, 100%, 45.1%);
  cursor: default;
}

.video-container {
  width: 90%; /* Adjust width for mobile */
  height: auto; /* Maintain aspect ratio */
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  position: relative; /* Ensure proper positioning */
}
.slider-container-left,
.slider-container-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 15px;
  background: #ffffff;
  box-shadow: 20px 20px 60px #e3e3e3, -20px -20px 60px #ffffff;
  height: fit-content;
  width: 96vw;
}

.slidecontainer {
  margin-bottom: 2vh;
}

.hidden-video-text .hidden-video-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

/* Media queries for larger screens */
@media (min-width: 821px) {
  .App {
    flex-direction: row;
  }

  .checkbox-parent {
    position: static; /* Reset position for desktop */
    width: 100%; /* Ensure it spans the full width */
    z-index: auto; /* Reset z-index */
    background-color: transparent; /* Remove background color */
    padding: 0; /* Remove padding */
  }

  .checkbox-wrapper {
    margin-bottom: -20px; /* Add spacing between video feed and checkbox-parent */
  }

  .welcome-text {
    display: block;
  }

  .slidecontainer {
    margin-top: 2vh;
  }

  .container {
    flex-direction: row;
    justify-content: center;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
    height: 65vh;
  }

  .slider-container-left,
  .slider-container-right {
    width: 15vw;
    height: 460px;
    margin: 0;
    box-shadow: 20px 20px 60px #e3e3e3, -20px -20px 60px #ffffff;
  }

  .slider-container-left {
    margin-left: 1vw;
  }

  .slider-container-right {
    margin-right: 1vw;
  }

  .slectionslidecontainer {
    margin: auto 0;
  }

  .slider {
    transform: rotate(270deg);
    -webkit-appearance: none;
    width: 12vw;
    height: 15px;
    margin-top: 12vh;
    border-radius: 5px;
    background: linear-gradient(90deg, #0066CC 0%, #c500cc 100%);
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }

  .video-container {
    width: 64vw;
    height: auto;
    height: 480px;
  }
}