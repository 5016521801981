/* Login.css */

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Koulen&family=Lato&family=Nunito&family=Playfair+Display:ital@1&family=Prata&family=Raleway:ital,wght@1,100&family=Roboto&family=Roboto+Condensed&family=Teko&display=swap');

.btn{
    font-family: Roboto, sans-serif;
    font-weight: 100;
    font-size: 14px;
    color: #fff;
    background: linear-gradient(90deg, #0066CC 0%, #c500cc 100%);

    border: none;
    box-shadow: none;
    border-radius: 50px;
    transition : 1000ms;
    transform: translateY(0);
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    text-align: center;
    margin-left: auto;
    justify-content: center;
}

.btn:hover{
    transition : 1000ms;
    /* padding: 10px 10px; */
    transform : translateY(-0px);
    background: linear-gradient(90deg, #0066cc7e 20%, #c500cc7c 100%);
    color: #fff;
    border: none;
}
.login-container {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }


  .center-text {
    text-align: center;
  }

  .margin-top {
    margin-top: 25px;
  }
  
  .background-image {
    position: absolute;
    background: linear-gradient(125deg, #0066CC 0%, #c500cc 50%, #cc5200 100%);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
  .login-items {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    color: black;
    text-align: center;
    align-items: center;
  }
  
  .login-form {
    background-color: rgba(0, 0, 0, 0.8); /* Dark background */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 1000px rgba(255, 255, 255, 0.3); /* Light shadow ORIGINALLY 10px */
    width: 400px; /* Adjust width as needed */
  }

  p {
    text-align: center;
    align-items: center;
    /* margin-bottom: 20px; */
    padding-top: 5%;
    color: #fff; /* White text */
  }
  
  .login-form h2 {
    text-align: center;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 5%;
    color: #fff; /* White text */
  }

  
  .form-group {
    margin-bottom: 20px;
    
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    color: #fff; /* White text */
  }
  
  input[type='email'],
  input[type='password'] {
    width: calc(100% - 20px); /* Adjust width to account for padding */
    padding: 10px;
    border: 1px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.2); /* Semi-transparent white */
    color: #fff; /* White text */
  }

  .bg-gradient-primary-to-secondary {
    background: linear-gradient(45deg, #2937f0, #9f1ae2) !important;
    color: #fff;
  }
  
  button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  