.shadow-sm {
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}

h1, h2 {
  text-align: center;
}

/* General layout */
.account-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  /* background-color: #f5f5f5; */
  overflow: hidden;
}

/* Sidebar */
.sidebar {
  width: 25%;
  max-width: 443px;
  background-color: #1f2937;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: 100%;
}

.profile-picture {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
  border: 3px solid #fff;
}

.user-info {
  text-align: center;
}

.user-info h2 {
  font-size: 20px;
  margin-bottom: 5px;
}

.user-info p {
  font-size: 14px;
  color: #cbd5e1;
}

/* Main content */
.main-content {
  flex: 1;
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
  padding: 20px;
  box-sizing: border-box;
}

.main-content h1 {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Chart container */
.chart-container {
  margin-bottom: 20px; /* Add spacing between charts */
  padding: 40px;
  background-color: #fff; /* Optional: Add a background color */
  border-radius: 8px; /* Optional: Add rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow */
  width: 100%; /* Full width of the parent container */
  height: 400px; /* Fixed height for all charts */
  margin-bottom: 20px; /* Add spacing between charts */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1214px;
  margin: 20px auto;
}
/* Recharts styles */
.recharts-tooltip-wrapper {
  font-size: 14px;
}

@media (max-width: 768px) {
  .account-container {
    flex-direction: column; /* Stack elements vertically */
  }

  .sidebar {
    width: 100%; /* Full width for the sidebar */
    height: fit-content;
    max-width: none; /* Remove max-width restriction */
    flex-direction: row; /* Arrange items in a row */
    justify-content: space-between; /* Space out items */
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .profile-picture {
    margin-bottom: 0; /* Remove bottom margin */
    margin: auto;
  }

  .user-info {
    text-align: left; /* Align text to the left */
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  .user-info h2 {
    font-size: 20px; /* Smaller font size */
  }

  .user-info p {
    font-size: 12px; /* Smaller font size */
    padding: 0;
  }

  .chart-container {
    max-width: 100%; /* Ensure it doesn't exceed the screen width */
    margin-right: 10px; /* Add smaller margins */
    padding: 20px; /* Adjust padding for smaller screens */
    height: auto; /* Allow height to adjust dynamically */
    box-sizing: border-box; /* Include padding in width calculation */
    overflow-x: hidden; /* Prevent horizontal overflow */
  }
}