html, body {
  overflow: hidden;
  height: 100%;
  position: fixed; /* Prevents movement */
  width: 100%;
}

* {
  overscroll-behavior: contain; /* Stops scrolling effects */
}

@media only screen and (max-width: 820px) {
    .slider-container-left, .slider-container-right {
      width: 96vw;
      position: relative;
      height: fit-content;
      /* flex-direction: column;
      justify-content: space-between; */
      margin: 0;
      align-items: center;
      box-shadow: none;
      height: fit-content;
    }
  
    .my-form {
      flex-direction: row;
      align-items: center;
    }
  
    .my-form label {
      padding-top: 1vh;
    }
  
    .my-form > div {
      width: 100%; /* Ensure each radio button container takes full width */
      display: flex;
      justify-content: center; /* Center align the radio button and label */
    }
  
    .dropdown {
      z-index: 1000;
    }
  
  
    .slectionslidecontainer {
      /* margin-top: 20%; */
      /* width: 15vw; Adjust as needed */
      width: 100%;
      /* margin-left: 0%; */
      position: relative;
    }
  
    .video-container {
      position: relative;
      width: 96vw;
      height: 35vh;
      bottom: -2vw;
      /* margin: 0; */
      z-index: 99;
    }
  
    .left-hand-side {
      left: 0;
      /* margin-left: 5%; */
    }
    .right-hand-side {
      /* position: absolute; */
      /* left: 0; */
      /* justify-self: center; */
      margin-left: auto;
      margin-right: auto;
    }
  
    .slider-container-left {
      position: fixed;
      /* margin-top: 98.18; */
      top: 12vh;
      left: 0;
      right: 0;
      z-index: 10;
      /* margin-bottom: 25vh; */
    }
  
    .slider-container-right {
      position: fixed;
      top: 82vh;
      left: 0;
      right: 0;
      z-index: 1000;
    }
  
    .slider-container {
      display: flex;
      justify-content: flex-start; /* Align children to the left */
      align-items: center; /* Center children vertically */
      text-align: center;
      left: 0;
    }
  
    .checkbox-wrapper {
      bottom: -9vh;
    }
  
    .goal-box {
      display: flex;
      /* top: 0%; */
      margin-bottom: 44.5vh;
    }
  
    .slider {
      transform: rotate(0deg);
      width: 80vw;
      margin: 0 0;
      margin-right: -12vw;
      justify-content: center;
    }
    .slidecontainer {
      z-index: 1000;
      margin-top: 0;
      margin-left: 0;
      display: flex;
      width: 50vw;
      align-items: center;
      flex-direction: column;
    }
  }